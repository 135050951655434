import Link from "next/link";
import { useRouter } from "next/router";

const Empty = ({
  children,
  isEmpty = false,
  icon,
  title,
  description,
  button = null,
  handleButtonClick = null,
  fromCart,
  titleClassName,
  descriptionClassName,
}) => {
  const { locale } = useRouter();
  return (
    <section
      className={
        !isEmpty ?
        "w-11/12 mx-auto py-4 sm:mt-28 sm:mb-52 flex items-center justify-center flex-col gap-2 sm:gap-7" : undefined
      }
    >
      {!isEmpty ? (
        <>
          {icon}
          {title ? (
            <h1
              className={`text-[15px] md:text-3xl font-semibold text-center ${titleClassName}`}
            >
              {title}
            </h1>
          ) : null}
          {description ? (
            <p
              className={`text-center text-zinc-600 max-w-96 text-sm max-sm:my-1 ${descriptionClassName}`}
            >
              {description}
            </p>
          ) : null}
          {fromCart ? (
            <div className="flex items-center gap-3">
              <button
                onClick={handleButtonClick}
                className="bg-red-600 text-white py-2 rounded-md w-36 sm:w-44 max-sm:text-[14px] text-center"
              >
                {button}
              </button>
              <Link
                href="/user/favourites"
                className="bg-black text-white py-2 rounded-md w-36 sm:w-44 max-sm:text-[14px] text-center"
              >
                {locale === "en" ? "Add From Favourites" : "الإضافة من المفضلة"}
              </Link>
            </div>
          ) : button ? (
            <button
              onClick={handleButtonClick}
              className="bg-red-600 text-white py-1.5 sm:py-2 max-sm:text-[15px] rounded-md w-72 sm:w-80"
            >
              {button}
            </button>
          ) : null}
        </>
      ) : (
        children
      )}
    </section>
  );
};

export default Empty;
