import Empty from "@/components/global/Empty";
import NotFoundIcon from "@/components/icons/NotFound";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const NotFound = () => {
  const { t } = useTranslation("common");
  const { push } = useRouter();
  return (
    <Empty
      icon={<NotFoundIcon className="size-72" />}
      title={t("404 Not found")}
      description={t("NotFoundDescription")}
      button={t("Back To Home")}
      handleButtonClick={() => push("/")}
    />
  );
};

export default NotFound;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
